import { invokeApi } from "../../bl_libs/invokeApi";
import { vissioon_base_url } from "../../config/config";

export const _create_user_design_project = (data) => {
  let requestObj = {
    path: "/api/project/add_project",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};
export const _delete_user_design_project = (data) => {
  let requestObj = {
    path: `/api/project/delete_project/${data}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_user_design_details = (id) => {
  let requestObj = {
    path: "/api/project/detail_project/" + id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _edit_project_name = (id, data) => {
  let requestObj = {
    path: "/api/project/edit_project_name/" + id,
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};
export const _update_user_design_project = (id, data) => {
  let requestObj = {
    path: "/api/project/edit_project/" + id,
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_user_created_designs = () => {
  let requestObj = {
    path: "/api/project/list_project",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _save_user_design = (data) => {
  let requestObj = {
    path: "/api/project/user_template",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _save_user_design_html = (data) => {
  let requestObj = {
    path: "/api/project/user_template_html",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _save_user_active_campaign = (data) => {
  let requestObj = {
    path: "/api/active_compaign/update_active_campaign",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _save_user_page_active_campaign = (data) => {
  let requestObj = {
    path: "/api/active_compaign/add_active_compaign",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _update_project_meta_settings = (data) => {
  let requestObj = {
    path: "/api/project/update_project_meta_settings",
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _add_domain_against_project = (data) => {
  let requestObj = {
    path: "/api/project/add_project_domain",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _add_ssl_against_domain = (data) => {
  let requestObj = {
    path: "/api/project/add_ssl_against_domain",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _make_unzip_file_of_project = (data) => {
  let requestObj = {
    path: "/api/project/make_unzip_file_of_project",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _publish_project_on_domain = (data) => {
  let requestObj = {
    path: "/api/project/attach_domain_with_project",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _project_subscribers_list = (data) => {
  let requestObj = {
    path: "/api/active_compaign/get_active_compaign_users",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _delete_project_subscriber_by_email = (data) => {
  let requestObj = {
    path: "/api/active_compaign/delete_active_compaign_user",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};
export const _use_project_version = (data) => {
  let requestObj = {
    path: "/api/project/use_project_version",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;

  return invokeApi(requestObj);
};
export const _add_project_version = (data) => {
  let requestObj = {
    path: "/api/project/save_version",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_project_version_list = (id) => {
  let requestObj = {
    path: `/api/project/get_version_list_by_project/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const _remove_project_version = (id) => {
  let requestObj = {
    path: `/api/project/delete_version/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _update_project_version_name = (data) => {
  let requestObj = {
    path: `/api/project/update_project_version_name`,
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;

  return invokeApi(requestObj);
};

// ActiveCampaign For Vissioon User

export const get_active_campaign_lists = async (id) => {
  const requestObj = {
    path: `/api/active_campaign/get_list/${id}`,
    method: "GET",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_mailchimp_lists = async (id) => {
  const requestObj = {
    path: `/api/mailchimp/get_mailchimp_list/${id}`,
    method: "GET",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_active_campaign_tags = async (id) => {
  const requestObj = {
    path: `/api/active_campaign/get_tag/${id}`,
    method: "GET",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_mailchimp_tags_against_list = async (coachId, listId) => {
  const requestObj = {
    path: `/api/mailchimp/get_mailchimp_segment_by_list/${coachId}/${listId}`,
    method: "GET",
    base_url: vissioon_base_url,
    headers: {
      "x-sh-auth": sessionStorage.getItem("vissiion_api_token"),
    },
  };
  return invokeApi(requestObj);
};
